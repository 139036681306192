import React from 'react';
import './Guarantees.css';

const guarantees = [
    {
      icon: <img className="guarantee-icon" src="https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/icons%2Fproof_sm.png?alt=media&token=87136880-e98a-4d77-8ebf-57c533ccdb16" />,
      caption: "We will send you samples for confirmation before shipping. If you don't like your samples, we offer a partial refund.",
      title: "You inspect first"
    },
    {
      icon: <img className="guarantee-icon" src="https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/icons%2Fshipping_truck.png?alt=media&token=22d49f1e-2ed4-4cd0-8f45-8fb1d6b87af0" />,
      caption: "Get your order within 12-days of ordering!",
      title: "Quick shipping"
    },
    {
      icon: <img className="guarantee-icon" src="https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/icons%2Fbow_resized.png?alt=media&token=efae7429-2989-4e93-963a-1a5c6dec3111" />,
      caption: "Each image is individually made by me, designed to bring out the best in you, and printed on premium quality holographic sheets.",
      title: "Handled with care"
    }
  ];
  
  const ProductGuarantees = () => {
    return (
      <div className="guarantees-container my-8 font-poppins mx-12">
        {guarantees.map((guarantee, index) => (
          <div key={index} className="guarantee-card">
            <div className="icon">{guarantee.icon}</div>
            <h3 className="font-bold text-xl my-4">{guarantee.title}</h3>
            <p className="caption">{guarantee.caption}</p>
          </div>
        ))}
      </div>
    );
  };
  
  export default ProductGuarantees;
  