import oscarAquarelleSm from '../style_guide_pics/oscar_aquarelle_sm.jpeg';
import oscarGraffitiSm from '../style_guide_pics/oscar_graffiti_sm.jpeg';
import oscarMemphisSm from '../style_guide_pics/oscar_memphis_sm.jpeg';

export const carouselInfo = [
  // {
  //   title: "Three style pack",
  //   thumbnail: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fthumbs%2F3style_resized_thumb.webp?alt=media&token=a9f6e388-3822-45ed-ac33-a84a3eea7cfc",
  //   alt: "Three style pack",
  //   description: "Choose from three different styles. Our top pick!",
  //   proof_label: "Two proofs x 3",
  //   media: [
  //     {
  //       src: {
  //         "high": "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2F3style-vmake-compressed.mp4?alt=media&token=c272354b-2dfd-4295-b3d4-fe4f40c8d655",
  //         "low": "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Flow_qual%2F3style-vmake-compressed.mp4?alt=media&token=7a0c920d-2f6c-4dff-910e-9cc904dd50cb"
  //       },
  //       type: "video",
  //       thumbnail: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Fthumbnails%2F3style-vmake-compressed.png?alt=media&token=8707d8d2-bfbf-496a-a2ea-4ee27daffd2e",
  //       translate: '0 -20%'
  //     }
  //   ],
  //   tags: ["Variety Pack", "Recommended"]
  // },
  {
    title: "Ani-you",
    thumbnail: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fthumbs%2Fjgirl_trans_resized_thumb.webp?alt=media&token=b057f1f0-cedc-4d47-bb1b-5210c4539a74",
    src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fjgirl_trans_thumb.webp?alt=media&token=0a8121d3-9a23-480a-ab87-1a6b003f6fa4",
    alt: "Ani-You",
    description: "A style based on the vibrant colors and expressive characters of anime. Transport yourself into a new world!",
    media: [
      {
        src: {
          high: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Fjgirl_enchanted-vmake.mp4?alt=media&token=92511c70-bbed-42b6-ba5a-52cb07333a6f",
          low: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Flow_qual%2Fjgirl_enchnated_compressed.mp4?alt=media&token=c7a9441e-9d38-450e-aacb-c2f0379721a3",
        },
        type: "video",
        thumbnail: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Fthumbnails%2Fjgirl_enchanted-vmake.png?alt=media&token=a772d253-07ac-4a22-9bd8-ced854d9c378",
        translate: '0 -20%'
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fjgirl_base.webp?alt=media&token=d95844b1-d17e-430e-9121-6e42a61aa8c3",
        trans: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fjgirl_trans.webp?alt=media&token=e50c4dbe-235d-4801-845f-1ee5c7613d51",
        type: "slider",
        imgHeight: '600'
      }
    ],
    tags: ["Anime", "People", "Portrait"]
  },
  {
    title: "Pixar Style",
    thumbnail: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fthumbs%2Fred_girl_trans_resized_thumb.webp?alt=media&token=321ca167-2e82-4aed-8928-d9f335f8549a",
    alt: "Pixar Style",
    description: "A style inspired by the animations of Pixar and Dreamworks.",
    media: [
      {
        src: {
          high: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Fpixar_girl_double.mp4?alt=media&token=ab8a2108-ae7b-4f59-ac2a-a9352378c556",
          low: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Flow_qual%2Fpixar_girl_double.mp4?alt=media&token=6f134d99-c507-468b-8eec-7ea61bb8649e",
        },
        type: "video",
        thumbnail: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Fthumbnails%2Fpixar_girl_double.png?alt=media&token=8d13dd94-45d1-4504-a632-e9bb9d263545",
        translate: '0 -10%'
      },
      {
        src: {
          high: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Fpixar_girl.mp4?alt=media&token=f1521bbd-b455-481f-a745-759cc9cba178",
          low: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Flow_qual%2Fpixar_girl.mp4?alt=media&token=b773c1e6-a156-42e3-869f-1c0f3489bb8b",
        },
        type: "video",
        thumbnail: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Fthumbnails%2Fpixar_girl.png?alt=media&token=9c207c2a-0ec5-4481-9d22-33c6115fe11f",
        translate: '0 -10%'
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fred_girl_bg_modified.webp?alt=media&token=f65773e1-c919-4b15-aeb3-8e673724005e",
        trans: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fred_girl_trans.webp?alt=media&token=9841e6a7-3a78-49c8-8a1d-d93c5af9ce1a",
        type: "slider",
        imgHeight: '252'
      }
    ],
    tags: ["3D", "People", "Portrait"]
  },
  {
    title: "Neo-pop",
    thumbnail: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fthumbs%2Faa_trans%20Large2_resized_thumb.webp?alt=media&token=3e73e9b9-e2de-4d14-8f06-116aba0c704c",
    alt: "Neo-pop",
    description: "A style inspired by the 80s, with a focus on dark colors and glowing accents.",
    media: [
      {
        src: {
          high: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Fnightcall-vmake-compressed.mp4?alt=media&token=22b543e3-96c6-4e7d-890c-582c90d37863",
          low: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Flow_qual%2Fnightcall-vmake-compressed.mp4?alt=media&token=a962e368-b9fe-4b6f-a3d0-8ad0aeb53a9f"
        },
        type: "video",
        thumbnail: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Fthumbnails%2Fnightcall-vmake-compressed.png?alt=media&token=a9e4f659-cf33-45bc-b4f0-7e9795daef15",
        translate: '0 -20%'
      },
      {
        src: {
          high: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Fneopop.mp4?alt=media&token=caf3e593-a221-4361-9d60-07e4666928f7",
          low: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Flow_qual%2Fneopop.mp4?alt=media&token=06765367-f83a-465e-8f26-b79afd0eb8f7",
        },
        type: "video",
        thumbnail: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Fthumbnails%2Fneopop.png?alt=media&token=e6ec8b89-9873-4a69-b994-216bf574d3a4",
        translate: '0 -10%'
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Faa_base%20Large.webp?alt=media&token=a38ecb3e-14de-469c-b58d-18d35641a1dd",
        trans: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Faa_trans%20Large2.webp?alt=media&token=09b00758-6728-43a9-a740-3bf00a09f308",
        type: "slider"
      }
    ],
    tags: ["People", "Portrait"]
  },
  // {
  //   title: "Harajuku",
  //   thumbnail: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fthumbs%2Fsgg_neon_thumb.webp?alt=media&token=501f3b12-0585-455f-8226-21f6f295f6fe",
  //   alt: "Harajuku",
  //   description: "A style inspired by the Harajuku fashion scene, with a focus on neon colors and bold patterns.",
  //   media: [
  //     {
  //       src: {
  //         high: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Fharajuku.mp4?alt=media&token=d937a25b-3208-4ec4-be53-1cfb203ec43f",
  //         low: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Flow_qual%2Fharajuku.mp4?alt=media&token=a79a114b-d402-40c6-9346-b6b715ff517b",
  //       },
  //       type: "video",
  //       thumbnail: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Fthumbnails%2Fharajuku.png?alt=media&token=261514d9-9544-4fa0-8bbe-3c75cf319397",
  //       translate: '0 -10%'
  //     },
  //     {
  //       src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fsgg_base.webp?alt=media&token=82963c26-7f97-4b17-a190-d5fccdb15525",
  //       trans: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fsgg_neon.webp?alt=media&token=4a3b9827-027e-40c0-9abc-361ca19f2e91",
  //       type: "slider",
  //       imgHeight: '484'
  //     },
  //   ],
  //   tags: ["Anime", "People", "Portrait"]
  // },
  {
    title: "Warm Hearth",
    src: "",
    thumbnail: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fthumbs%2Fcouple_trans%20Large_resized_thumb.webp?alt=media&token=13b81860-0e9a-4c2b-beca-5c7c661c033a",
    alt: "Warm Hearth",
    description: "A style inspired by cozy home decor, with a focus on warm colors and rustic textures.",
    media: [
      {
        src: {
          high: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Fcouple_warm_compressed.mp4?alt=media&token=ddb5dc42-736d-47b7-b39a-e6cf5f89e74e",
          low: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Flow_qual%2Fcouple_warm_compressed.mp4?alt=media&token=b50cfd8f-9b54-41aa-b230-73cc1234f8fe",
        },
        type: "video",
        thumbnail: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Fthumbnails%2Fcouple_warm_compressed.png?alt=media&token=0073cc91-260b-4b2e-a0cb-66dfe7f5f724",
        translate: '0 -20%'
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fcouple_base%20Large.webp?alt=media&token=24aeeded-6e7e-490c-8b4d-adbb4a20bce9",
        trans: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fcouple_trans%20Large.webp?alt=media&token=9328a0c6-eb85-4c4b-b40c-f222c14bc4ee",
        type: "slider",
        imgHeight: '600'
      }
    ],
    tags: ["Abstract", "People", "Poses"]
  },
  {
    title: "Posh sketch",
    src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fthumbs%2Foscar_groovy_resized_thumb.webp?alt=media&token=4c65eb84-282b-4848-8864-9c73ac9dd3a6",
    alt: "Posh sketch",
    description: "A style inspired by modern design, with a focus on geometric shapes and bold patterns.",
    media: [
      {
        src: {
          high:"https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Foscar_posh_sketch.mp4?alt=media&token=d9b26423-c6ec-4a80-827a-68446a4eff7a",
          low:"https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Flow_qual%2Foscar_posh_sketch.mp4?alt=media&token=7ada049c-c1fe-4c82-bf4b-a24810b43491",
        },
        type: "video",
        thumbnail: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Fthumbnails%2Foscar_posh_sketch.png?alt=media&token=81cd6e2b-5953-4680-b84c-1d7dfa295b52",
        translate: '0 -10%'
      },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Foscar_base.webp?alt=media&token=37c93bf0-af9d-4add-9bd9-6d80cc82b579",
        trans: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Foscar_groovy.webp?alt=media&token=873f540d-2abf-46a7-a384-310d6f50c3ad",
        type: "slider"
      },
    ],
    tags: ["Abstract", "Pets", "Poses"]
  },
  {
    title: "Watercolor",
    src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fthumbs%2Fwedding_aquarelle_cropped_resized_thumb.webp?alt=media&token=31b352ee-763b-47bf-b671-363e13d626cc",
    alt: "Watercolor",
    description: "A style inspired by watercolor paintings, with a focus on soft colors and dreamy textures.",
    media: [
      {
        src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fwedding_base.webp?alt=media&token=9e1fa2b5-ed5f-4ac8-8e5e-4fbeece2daa8",
        trans: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fwedding_aquarelle_cropped.webp?alt=media&token=f3bb46d5-8416-48d3-9f35-a6ae577e9628",
        type: "slider",
        // imgHeight: '252'
      }
    ],
    tags: ["People", "Pets"]
  },
  // {
  //   title: "Graffiti",
  //   src: oscarGraffitiSm,
  //   alt: "Graffiti",
  //   description: "A style inspired by street art, with a focus on bold colors and expressive designs.",
  //   media: [
  //     {
  //       src: {
  //         high:"https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Foscar_graffiti.mp4?alt=media&token=73cfd29d-fbf2-4e43-97e6-ca8157961565",
  //         low: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Flow_qual%2Foscar_graffiti.mp4?alt=media&token=b1719efb-b394-4e6d-8ba5-33407593d8b5",
  //       },
  //       type: "video",
  //       thumbnail: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/videos%2Fthumbnails%2Foscar_graffiti.png?alt=media&token=936e1cba-da98-4721-91ab-2a229238ca08",
  //       translate: '0 -10%'
  //     },
  //     {
  //       src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Foscar_base.webp?alt=media&token=37c93bf0-af9d-4add-9bd9-6d80cc82b579",
  //       trans: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Foscar_graffiti.webp?alt=media&token=582186df-04db-4206-ae46-409e31d2d7de",
  //       type: "slider",
  //     }
  //   ],
  //   tags: ["People", "Portrait", "Poses"]
  // },
]
